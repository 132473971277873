// AUTHENTICATION
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const ACTIVITY_DATE = "ACTIVITY_DATE";
export const ADD_ACTIVITY = "ADD_ACTIVITY";
export const EDIT_ACTIVITY = "EDIT_ACTIVITY";
export const ADD_SURVEY = "ADD_SURVEY";
export const ADD_PROJECTION = "ADD_PROJECTION";
export const ADD_BHA_ASSETS = "ADD_BHA_ASSETS";
export const ADD_BHA_MOTOR = "ADD_BHA_MOTOR";
export const ADD_DRILLPIPE_ASSETS = "ADD_DRILLPIPE_ASSETS";
export const SET_USER = "SET_USER";
export const SET_USER_ID = "SET_USER_ID";
export const SET_USER_LEVEL = "SET_USER_LEVEL";
export const SET_INITIAL_PAGE = "SET_INITIAL_PAGE";
export const SET_FOCUS_WELL = "SET_FOCUS_WELL";
export const SET_ACTIVE_JOB = "SET_ACTIVE_JOB";
export const SET_ACTIVE_PHASE = "SET_ACTIVE_PHASE";
export const SET_FOCUS_PHASE_DETAILS = "SET_FOCUS_PHASE_DETAILS";
export const SET_URL_MATCH = "SET_URL_MATCH";
export const ADD_RECIEVED_SHIPMENT = "ADD_RECIEVED_SHIPMENT";
export const SET_USER_PARTNER = "SET_USER_PARTNER";
export const SET_USER_PARTNER_TYPE = "SET_USER_PARTNER_TYPE";
export const SET_USER_BU = "SET_USER_BU";
export const SET_USER_VIEW_PARTNER = "SET_USER_VIEW_PARTNER";
export const SET_USER_VIEW_STYLE = "SET_USER_VIEW_STYLE";
export const SET_USER_COUNTRY = "SET_USER_COUNTRY";
export const SET_USER_MULTI_COUNTRY = "SET_USER_MULTI_COUNTRY";
export const SET_USER_DISTRICT = "SET_USER_DISTRICT";
export const SET_PERSON_ROUTE_APPS = "SET_PERSON_ROUTE_APPS";
export const SET_PERSON_PERFORMANCE_APPS = "SET_PERSON_PERFORMANCE_APPS";
export const SET_PERSON_DOCK_APPS = "SET_PERSON_DOCK_APPS";
export const ADD_SCENARIO = "ADD_SCENARIO";
export const UPDATE_SCENARIO = "UPDATE_SCENARIO";
export const TRACK_SCENARIO_CHANGE = "TRACK_SCENARIO_CHANGE";
export const TASK_PROGRESS_MODAL = "TASK_PROGRESS_MODAL";
export const RIGHT_INSIGHT_MODAL = "RIGHT_INSIGHT_MODAL";
export const OPEN_INSIGHT_FORM = "OPEN_INSIGHT_FORM";
export const CLOSE_INSIGHT_FORM = "CLOSE_INSIGHT_FORM";

