export const astrabrand = ['#2c5c69', '#00e29f', '#9b9da0', '#0a2d47', '#42A5C1', '#CBC9c9', '#7a7d81', "#ff5600", '#ea4343', '#FED72F', '#b30211']

export const astraC5G = ['#00e29f', '#00D08C', '#68FDCD', '#39FFBE', '#00FFAB', '#005A3D']
export const astraC5B = ['#045D76', '#057C9D', '#2c5c69', '#42a5c1', '#01629c', '#94bbfb', '#679ffa']
export const astraElectric = ['#68FDCD', '#84A64C', '#d5941f', '#ea4343', '#057c9d', '#6e74cd', '#ec409b', '#ff5600', '#0a2d47', '#56691e', '#5e5d5d', '#99362d', '#53b1c4', '#de8e47', '#b27b7b', '#bec793', '#999999', '#cc938e', '#057C9D', '#deaa7c', '#000000']
export const astraC5Grey = ['#7a7d81', '#95979a', '#afb1b3', '#cacbcd', '#e4e5e6', '#9b9da0', '#afb1b3', '#c3c4c6', '#d7d8d9']
export const astraC10_Multi = ['#2c5c69', '#00e29f', '#7a7d81', '#057C9D', '#0a2d47', '#cbc9c9', '#56691e', '#5e5d5d', '#99362d', '#53b1c4', '#de8e47', '#b27b7b']
export const astraDVDSegments = ['#0a2d47', '#56691e', '#5e5d5d', '#99362d', '#53b1c4', '#de8e47', '#b27b7b', '#bec793', '#999999', '#cc938e', '#057C9D', '#deaa7c', '#000000']
export const astraC6_Multi = ['#2c5c69', '#bec793', '#999999', '#cc938e', '#057C9D', '#deaa7c', '#cbc9c9', '#000000']
export const astraC6_Multi_3Op = ['#2c5c694d', '#bec7934d', '#9999994d', '#cc938e4d', '#057C9D4d', '#deaa7c4d', '#cbc9c94d', '#0000004d']
export const astraC15_Multi = ['#2c5c69', '#bec793', '#999999', '#cc938e', '#057C9D', '#deaa7c', '#cbc9c9', '#0a2d47', '#56691e', '#5e5d5d', '#99362d', '#53b1c4', '#de8e47', '#b27b7b', '#000000']
export const astraC6_Multi_Compliment = ['#0a2d47', '#56691e', '#5e5d5d', '#99362d', '#53b1c4', '#de8e47', '#b27b7b']
export const astraC10G = ['#7b7d7c', '#808475', '#848c6d', '#889465', '#8c9b5d', '#8fa354', '#92ab4a', '#94b23f', '#96ba32', '#00e29f'];
export const astraC20G = ['#7b7d7c', '#7d8179', '#808475', '#828872', '#848b6e', '#868f6a', '#889267', '#899663', '#8b9a5f', '#8d9d5b', '#8ea157', '#8fa452', '#91a84e', '#92ac49', '#93af43', '#94b33e', '#95b738', '#96bb31', '#97be29', '#00e29f'];
export const astraColorMode = ['#FCFCFC', '#1e1e24', 'rgba(252,252,252,0.71)']

export const astraElectric_3Op = ['#d5941f4d', '#ea43434d', '#057c9d4d', '#6e74cd4d', '#ec409b4d', '#ff56004d', '#0a2d474d', '#56691e4d', '#5e5d5d4d']
export const astraElectric_2Op = ["#d5941f33", "#ea434333", "#057c9d33", "#6e74cd33", "#ec409b33", "#ff560033", "#0a2d4733", "#56691e33", "#5e5d5d33"];

export const astraC10B = ['#7b7d7c', '#757f7e', '#6e8080', '#678282', '#5f8484', '#578586', '#4e8788', '#43888a', '#358a8c', '#238b8e'];
export const astraC20B = ['#7b7d7c', '#787e7d', '#757f7e', '#727f7f', '#6f8080', '#6c8181', '#688282', '#658383', '#618384', '#5d8484', '#598585', '#558686', '#518687', '#4c8788', '#478889', '#41888a', '#3b898b', '#358a8c', '#2d8a8d', '#238b8e'];
export const astraC20B_50P_RGBA = ['rgba(123, 125, 124, .5)', 'rgba(120, 126, 125, .5)', 'rgba(117, 127, 126, .5)', 'rgba(114, 127, 127, 0.5)', 'rgba(111, 128, 128, 0.5)', 'rgba(97, 131, 132, .5)', 'rgba(104, 130, 130, .5)', 'rgba(101, 131, 131, .5)', 'rgba(97, 131, 132, .5)', 'rgba(93, 132, 132, .5)', 'rgba(89, 133, 133, .5)', 'rgba(85, 134, 134, .5)', 'rgba(81, 134, 135, .5)', 'rgba(76, 135, 136, .5)', 'rgba(71, 136, 137, .5)', 'rgba(65, 136, 138, .5)', 'rgba(59, 137, 139, .5)', 'rgba(53, 138, 140, .5)', 'rgba(45, 138, 141, .5)', 'rgba(35, 139, 142, .5)'];

export const astraDark = ["#1c1c1c", "#232323", "#2e2e2e", "#373737", "#48453e", "#5a5b5d", "#FF69B4"];

export const astraPurple3Phase = ['#e2baff', '#7c649c', '#231942']// ltd
export const astraYellow3Phase = ['#f7b538', '#ca7d1a', '#9a4900'] // ltd
export const astraGreen3Phase = ['#68fdcd', '#379c64', '#00450a']// ltd
export const astraTeal4Phase = ['#a7ffff', '#70cde0', '#449bbf', '#2a6a99']// ltd
export const astraRed5Phase = ['#ffdac0', '#f1b796', '#e39370', '#d46e50', '#c44536']// ltd
export const astraC5Orange = ['#e9d8a6', '#ee9b00', '#ca6702', '#bb3e03', '#ae2012']//ltd
export const astraC6Blue = ['#00abd6', '#1f90af', '#29768b', '#2c5c69']

export const astraC20BG = ['#238b8e', '#348a8c', '#41898a', '#4b8987', '#548984', '#5c8a81', '#638b7e', '#698c7a', '#6f8e76', '#759072', '#7a936e', '#7f9669', '#839a63', '#879e5d', '#8ba357', '#8ea84f', '#91ae47', '#94b43d', '#96bb31', '#00e29f'];
export const astraC20BG_3Op = ['#238b8eB3', '#348a8cB3', '#41898aB3', '#4b8987B3', '#548984B3', '#5c8a81B3', '#638b7eB3', '#698c7aB3', '#6f8e76B3', '#759072B3', '#7a936eB3', '#7f9669B3', '#839a63B3', '#879e5dB3', '#8ba357B3', '#8ea84fB3', '#91ae47B3', '#94b43dB3', '#96bb31B3', '#00e29fB3'];
export const astraC10BG = ['#238b8e', '#428989', '#568984', '#658b7d', '#728f75', '#7d956b', '#869d5f', '#8ea751', '#94b33e', '#00e29f'];
export const astraC10BWG = ['#00383c', '#0e686c', '#329b9e', '#70cfd1', '#f5f5f5', '#a4ce33', '#6d9a0f', '#3f6700', '#183800'];

export const alertLevels = ['#C43438', '#C68135', '#E1B95B', '#99c126']
export const alertLevelsTransparent = ['#C4343840', '#C6813540', '#E1B95B40', '#99c12640']
export const alertLevels_30p = ['#C43438B3', '#C68135B3', '#E1B95BB3', '#00e29fB3']

export const formationColors = ['#27251f', '#8b836d', '#3b382f', '#9c9481', '#4f4a3e', '#aba595', '#635d4e', '#bbb6a9', '#77705e', '#cbc7bd', '#312515', '#ad824c', '#4a3720', '#ba9363', '#634a2b', '#c5a47b', '#7b5c36', '#d0b594', '#946f41', '#dbc6ad', '#863816', '#351609', '#7d3415', '#3e190b', '#743014', '#471d0c', '#6b2c13', '#50200e', '#622811', '#59240f']
export const formationTransparentColors = ['#27251f4D', '#8b836d4D', '#3b382f4D', '#9c94814D', '#4f4a3e4D', '#aba5954D', '#635d4e4D', '#bbb6a94D', '#77705e4D', '#cbc7bd4D', '#3125154D', '#ad824c4D', '#4a37204D', '#ba93634D', '#634a2b4D', '#c5a47b4D', '#7b5c364D', '#d0b5944D', '#946f414D', '#dbc6ad4D', '#8638164D', '#3516094D', '#7d34154D', '#3e190b4D', '#7430144D', '#471d0c4D', '#6b2c134D', '#50200e4D', '#6228114D', '#59240f4D']

export const casingColors = ['#70706F', '#A1A2A3', '#7D7D7A', '#B3B6B5', '#8E8D8D', '#BEC0C2']
export const casingTransparentColors = ['#70706F4D', '#A1A2A34D', '#7D7D7A4D', '#B3B6B54D', '#8E8D8D4D', '#BEC0C24D']


export const astraCS10B = [[0, '#7b7d7c'], [0.1, '#7b7d7c'], [0.1, '#757f7e'], [0.2, '#757f7e'],
[0.2, '#6e8080'], [0.3, '#6e8080'],
[0.3, '#678282'], [0.4, '#678282'],
[0.4, '#5f8484'], [0.5, '#5f8484'],
[0.5, '#578586'], [0.6, '#578586'],
[0.6, '#4e8788'], [0.7, '#4e8788'],
[0.7, '#43888a'], [0.8, '#43888a'],
[0.8, '#358a8c'], [0.9, '#358a8c'],
[0.9, '#238b8e'], [1, '#238b8e']];

export const astraCS10BG = [[0, '#238b8e'], [0.1, '#238b8e'], [0.1, '#419184'], [0.2, '#419184'],
[0.2, '#549879'], [0.3, '#549879'],
[0.3, '#678282'], [0.4, '#678282'],
[0.4, '#649f6e'], [0.5, '#649f6e'],
[0.5, '#70a662'], [0.6, '#70a662'],
[0.6, '#7cad56'], [0.7, '#7cad56'],
[0.7, '#86b448'], [0.8, '#86b448'],
[0.8, '#8fbb37'], [0.9, '#8fbb37'],
[0.9, '#00e29f'], [1, '#00e29f']];


export const astraCS10WB = [
    [0, '#238b8e'], [0.1, '#238b8e'],
    [0.1, '#45989a'], [0.2, '#45989a'],
    [0.2, '#60a4a6'], [0.3, '#60a4a6'],
    [0.3, '#78b1b2'], [0.4, '#78b1b2'],
    [0.4, '#8fbebf'], [0.5, '#8fbebf'],
    [0.5, '#a6cbcb'], [0.6, '#a6cbcb'],
    [0.6, '#bcd8d8'], [0.7, '#bcd8d8'],
    [0.7, '#d2e5e5'], [0.8, '#d2e5e5'],
    [0.8, '#e9f2f2'], [0.9, '#e9f2f2'],
    [0.9, '#ffffff'], [1, '#ffffff']];

export const astraC10BB = ['#238b8e', '#419184', '#549879', '#649f6e', '#70a662', '#7cad56', '#86b448', '#8fbb37', '#00e29f'];
export const astraC10WB = ['#ffffff', '#e9f2f2', '#d2e5e5', '#bcd8d8', '#a6cbcb', '#8fbebf', '#78b1b2', '#60a4a6', '#45989a', '#238b8e']
export const graph20 = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']
export const astragraph = ['#0a2d47', '#00e29f', '#057C9D', '#7a7d81', '#2c5c69', '#99362d', '#cbc9c9', '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000']
export const astraElectricGraph = ['#0a2d47', '#68FDCD', '#d5941f', '#ea4343', '#057c9d', '#6e74cd', '#ec409b', '#ff5600', '#0a2d47', '#56691e', '#5e5d5d', '#99362d', '#53b1c4', '#de8e47', '#b27b7b', '#bec793', '#999999', '#cc938e', '#057C9D', '#deaa7c', '#000000']
//export const astragraph = ['#00383c', '#7a0000', '#e7f0b3', '#e16e1d', '#70cfd1', '#00e29f', '#ec6766', '#fdb069', '#8bc6f9', '#00383c', '#7a0000', '#e7f0b3', '#e16e1d', '#70cfd1', '#00e29f', '#ec6766', '#fdb069', '#8bc6f9']