import axios from 'axios';
import React from 'react';
import { Card } from 'reactstrap';
import { astrabrand } from '../../../variables/ColorPalette.js';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bgcolor: this.props.bgcolor,
      iconColor: this.props.iconColor,
      itemIcon: this.props.itemIcon,
      itemMessage: this.props.itemMessage,
      userId: this.props.userId,
      sourcePage: this.props.sourcePage,
      sourceSection: this.props.sourceSection,
      posturl: this.props.posturl,
      header: this.props.header,
      options: this.props.options,
      chartRef: this.props.chartRef,
    };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    console.log(this.props.options, this.props.chartRef);
    this.setState({
      failUrl: this.props.failUrl,
      posturl: this.props.posturl,
      header: this.props.header,
      width: this.props.width,
      hasError: true
    }, () => {
      this.errorLogging(error)
    });

    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
  }


  errorLogging = (error) => {
    let location_url = window.location.href
    let errorString = error
    if (typeof errorString !== 'string') {
      errorString = error.stack
    }
    const errorDetails = {
      user: this.props.userId ? this.props.userId : null,
      url: location_url,
      page_name: this.props.sourcePage ? this.props.sourcePage : null,
      section_name: this.props.sourceSection ? this.props.sourceSection : null,
      error_message: typeof errorString == 'string' ? errorString : "not a string",
    };
    console.log(errorString)
    let failUrl = this.state.failUrl
    let submitUrl = this.state.posturl?this.state.posturl:failUrl
    if (this.props.sessionClearId) {
      sessionStorage.removeItem(this.props.sessionClearId);
    }
    try {
      if (submitUrl) {
        axios.post(submitUrl + 'frontend-failure/', errorDetails, this.state.header)
      }
    } catch (error) {
      if (failUrl) {
        axios.post(failUrl + 'frontend-failure/', errorDetails, this.state.header)
          .catch(error => {
            console.error('Error posting to failUrl:', error);
          });
      }
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto',
          width: this.state.width ? this.state.width : '90%'
        }}>
          <Card className="card-stats" align="center" style={{
            backgroundColor: 'transparent',
            height: 120,
            width: this.state.width ? this.state.width : '100%',
          }}>

            <div style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              marginBottom: 15,
              marginTop: 15
            }} className={this.state.iconColor ? "info-icon-white " + this.state.iconColor : "info-icon-white icon-warning"}>
              <i className={this.state.itemIcon ? this.state.itemIcon : "tim-icons icon-alert-circle-exc"} />
            </div>
            <h4 style={{
              color: astrabrand[3]
            }}>{this.state.itemMessage ? this.state.itemMessage : "Something went wrong. Please refine the selections and try again."}</h4>

          </Card>
        </div>
      )
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
